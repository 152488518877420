import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material'
import './purchaseorder.css'
// import moment from 'moment'
import logo from 'assets/images/Rug-Logo-Surya-removebg-preview.png'

const PoInvoiceTemplate = (props) => {
    const { purchaseOrderDetail, rows } = props
    

    const totalSqm = rows.reduce((total, order) => total + (Number(order.totalSqm) || 0), 0)
    const totalFob = rows.reduce((total, order) => total + (Number(order.totalFOB) || 0), 0)

    // const vendor = rows.length > 0 ? rows[0].vendor || 'N/A' : 'N/A'

    // const formatDate = (date) => {
    //     if (!date) return 'N/A'
    //     return moment(date).format('DD/MM/YYYY')
    // }

    const allowedKeys = [
        // { key: 'poNumber', label: 'PO Number' },
        // { key: 'supplierName', label: 'Supplier Name' },
        // { key: 'shipTo', label: 'Ship To' },
        // { key: 'shipVia', label: 'Ship Via' },
        // { key: 'status', label: 'Status' },
        // { key: 'poDate', label: 'PO Date' },
        // { key: 'poLoadDate', label: 'PO Load Date' },
        // { key: 'ready_date', label: 'Ready Date' },
        // { key: 'poDueDate', label: 'PO Due Date' },
        // { key: 'vendor', label: 'Vendor' },
        { key: 'Gulistan Polat', label: 'Purchase Representative' },
        { key: '09/25/2024 12:58:36', label: 'Order Date' }
    ]

    return (
        <div className="pdf_div pdf-page size-a4" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* <Box display="flex" flexDirection="column" minHeight="100vh"> */}
            <Box flexGrow={1}>
                <Box display="flex" justifyContent="center" mt={2} style={{ marginBottom: '50px' }}>
                    <Box style={{ width: '100%', borderBottom: '1px solid black' }}>
                        <img
                            src={logo}
                            alt="Company Logo"
                            style={{ width: '200px', marginBottom: '20px', marginTop: '15px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={4} style={{ paddingLeft: '30px' }}>
                        <Box>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                Shipping address:
                            </Typography>
                            <Typography variant="body2">
                                Turkey Warehouses
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '30px' }}>
                        <Box>
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                Vendor:
                            </Typography>
                            <Typography variant="body2">
                                {purchaseOrderDetail?.supplierName}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <div className='top_div'>
                    <Box
                        className="header_top"
                        mt={2}
                    >
                        <Typography
                            variant="h5"
                            align="left"
                            style={{ fontSize: '30px' }}
                        >
                            Purchase Order #{purchaseOrderDetail?.poNumber}
                        </Typography>

                        <Typography variant="p" gutterBottom style={{ fontWeight: 'bold' }}>
                            {purchaseOrderDetail?.status}
                        </Typography>
                    </Box>
                </div>

                <Grid
                    container
                    spacing={3}
                    className='po_details'
                >
                    {allowedKeys.map(({ key, label }) => (
                        <Grid item xs={6} sm={4} md={3} key={key} style={{ paddingBottom: '24px' }}>
                            <Typography
                                variant="body2"
                                className='data_key'
                            >
                                {label}
                            </Typography>
                            <Typography variant="body2" style={{ color: '#555' }}>
                                {/* {key === 'vendor' ? vendor : (key.endsWith('Date') ? formatDate(purchaseOrderDetail[key]) : purchaseOrderDetail[key]) || 'N/A'} */}
                                {key}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>

                {/* <Box mt={3} mb={2}>
                <Typography
                    variant="p"
                    gutterBottom
                    className='product_heading'
                >
                    Products Details
                </Typography>
            </Box> */}

                <TableContainer className='table_product' >
                    <Table size="small" className="inner_table" style={{ tableLayout: 'fixed' }}>
                        <TableHead>
                            <TableRow style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                                {/* {['SKU', 'Size', 'FOB', 'Sqm', 'Total FOB', 'Total Sqm', 'V. Qty', 'Length', 'Width', 'UPC'].map((header) => ( */}
                                {['SKU', 'UPC code', 'Vendor ID', 'SQM', 'Total SQM', 'Qty', 'Unit Price', 'Amount'].map((header) => (
                                    <TableCell
                                        key={header}
                                        className='table_header'
                                    >
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((order, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        className='table_cell'
                                    >
                                        {order.sku ? (
                                            <a
                                                href={order.name}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='table_cell_link table_cell_last'
                                            >
                                                {order.sku}
                                            </a>
                                        ) : 'N/A'}
                                    </TableCell>
                                    {/* <TableCell className='table_cell'>{order.size || 'N/A'}</TableCell> */}
                                    <TableCell className='table_cell_last'>{order.upc || 'N/A'}</TableCell>
                                    <TableCell className='table_cell_last'>{order.vendor || 'N/A'}</TableCell>
                                    {/* <TableCell className='table_cell'>{Number(order.FOB).toFixed(2)}</TableCell> */}
                                    <TableCell className='table_cell_last'>{Number(order.SQMeterPerPiece).toFixed(2)}</TableCell>
                                    {/* <TableCell className='table_cell'>{Number(order.totalFOB).toFixed(2)}</TableCell> */}
                                    <TableCell className='table_cell_last '>{Number(order.totalSqm).toFixed(2)}</TableCell>
                                    <TableCell className={`table_cell_last fw-bold ${order.validatedQty ? 'text-success':"" || order.proposedQty ? 'text-warning':''||order.expectedQty ? 'text-success':''}`}>{order.validatedQty || `${order.proposedQty ? `Proposed ${order.proposedQty}`:''}` ||`${order.expectedQty ? `Expected ${order.expectedQty}`:''}`}</TableCell>
                                    <TableCell className='table_cell_last'>{Number(order.FOB).toFixed(2)}</TableCell>
                                    <TableCell className='table_cell_last'>{Number(order.totalFOB).toFixed(2)}</TableCell>
                                    {/* <TableCell className='table_cell'>{order.length || 'N/A'}</TableCell> */}
                                    {/* <TableCell className='table_cell_last'>{order.width || 'N/A'}</TableCell> */}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box mt={2} style={{ display: 'flex', justifyContent: 'end', paddingRight: '30px' }}>
                    <Box style={{ borderTop: '1px solid #e0e0e0', width: 'fit-content', paddingTop: '5px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', color: '#007782' }}>
                            Total SQM:
                        </Typography>
                        <Typography variant="body2" style={{ fontWeight: 'bold', marginLeft: '70px' }}>
                            {totalSqm.toFixed(2)}
                        </Typography>
                    </Box>
                </Box>
                <Box mt={2} style={{ display: 'flex', justifyContent: 'end', paddingRight: '30px' }}>
                    <Box style={{ borderTop: '1px solid #e0e0e0', width: 'fit-content', paddingTop: '5px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" style={{ fontWeight: 'bold', color: '#007782' }}>
                            Total:
                        </Typography>
                        <Typography variant="body2" style={{ fontWeight: 'bold', marginLeft: '70px' }}>
                            {totalFob.toFixed(2)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/* </Box> */}
            {/* <Box mt={4} py={2} style={{ borderTop: '1px solid black', display: 'flex', justifyContent: 'space-around', flexShrink: 0 }}>
                <Typography variant="body2">
                    +31 718080145
                </Typography>
                <Typography variant="body2">
                    <a href="mailto:customer@surya.com" className="footer_link" style={{ color: "black", textDecoration: 'none' }}>
                        customer@surya.com
                    </a>
                </Typography>
                <Typography variant="body2">
                    <a href="https://pom.surya.eu" target="_blank" rel="noopener noreferrer" className="footer_link" style={{ color: "#1384ff", textDecoration: 'none' }}>
                        https://pom.surya.eu
                    </a>
                </Typography>
                <Typography variant="body2">
                    DE344089904
                </Typography>
            </Box> */}
        </div >
    )
}

PoInvoiceTemplate.propTypes = {
    selectedPdfType: PropTypes.string.isRequired,
    purchaseOrderDetail: PropTypes.object.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PoInvoiceTemplate