import React from 'react'
import PropTypes from 'prop-types' // Import PropTypes for prop type validation
import { FormControl, Grid, MenuItem, Select, TextField, Tooltip, InputLabel } from '@mui/material'
import { Button as ProductButton } from '@mui/material' // Import Button as ProductButton
import { GrPowerReset } from 'react-icons/gr'

const OrderFilters = ({ filters, handleFilterChange }) => {
  return (
    <Grid
      container
      spacing={1}
      alignItems='center'
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{ marginBottom: '20px', '& > *': { marginX: '5px' } }}
    >
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Tooltip title='Search Order'>
          <FormControl fullWidth size='small' variant='outlined'>
            <TextField
              label='Order ID'
              size='medium'
              variant='filled'
              value={filters.order_id || ''}
              onChange={handleFilterChange('order_id')}
            />
          </FormControl>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Tooltip title='Search Customer'>
          <FormControl fullWidth variant='filled'>
            <TextField
              label='Customer'
              size='medium'
              variant='filled'
              value={filters.customer || ''}
              onChange={handleFilterChange('customer')}
            />
          </FormControl>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <FormControl fullWidth variant='filled'>
          <InputLabel id='demo-simple-select-filled-label'>State</InputLabel>
          <Select
            labelId='demo-simple-select-filled-label'
            id='demo-simple-select-filled'
            value={filters.state || ''}
            onChange={handleFilterChange('state')}
          >
            <MenuItem value=''>
              <em>All</em>
            </MenuItem>
            <MenuItem value='shipping'>
              <em>Shipping</em>
            </MenuItem>
            <MenuItem value='shipped'>
              <em>Shipped</em>
            </MenuItem>
            <MenuItem value='cancelled'>
              <em>Cancelled</em>
            </MenuItem>
            <MenuItem value='waiting_acceptance'>
              <em>Waiting_acceptance</em>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <FormControl fullWidth variant='filled'>
          <InputLabel id='demo-simple-select-filled-label'>Source</InputLabel>
          <Select
            labelId='demo-simple-select-filled-label'
            id='demo-simple-select-filled'
            value={filters.source || ''}
            onChange={handleFilterChange('source')}
          >
            <MenuItem value=''>
              <em>All</em>
            </MenuItem>
            <MenuItem value='bulk'>
              <em>Bulk</em>
            </MenuItem>
            <MenuItem value='manual'>
              <em>Manual</em>
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <FormControl fullWidth variant='outlined'>
          <TextField
            label='Creation Date'
            type='date'
            size='medium'
            variant='filled'
            InputLabelProps={{
              shrink: true
            }}
            value={filters.creation_date || ''}
            onChange={handleFilterChange('creation_date')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <ProductButton
          sx={{
            width: '100%',
            fontSize: {
              xs: '0.8rem',
              sm: '1rem',
              md: '1.1rem',
              lg: '1rem'
            },
            padding: {
              xs: '8px 12px',
              sm: '10px 14px',
              md: '12px 16px',
              lg: '14px 18px'
            }
          }}
          size='large'
          variant='outlined'
          onClick={handleFilterChange('null')}
        >
          Reset{' '}
          <span>
            <GrPowerReset className='reset-icon ms-2' size='1.5rem' />
          </span>
        </ProductButton>
      </Grid>
    </Grid>
  )
}

OrderFilters.propTypes = {
  filters: PropTypes.object.isRequired, // Validate filters prop as an object
  handleFilterChange: PropTypes.func.isRequired // Validate handleFilterChange prop as a function
}

export default OrderFilters
