import React, { useContext, useState } from 'react'
import { Tooltip, TextField, Grid, FormControl, TablePagination, Skeleton } from '@mui/material'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CustomisedInput from 'components/formElements/CustomisedInput'
import { Button, Button as ProductButton, MenuItem, InputLabel, Select } from '@mui/material'
import Modal from 'components/uiElements/modal/Modal'
import { UserContext } from 'context/AuthContext/UserContext'
import { GrPowerReset } from 'react-icons/gr'
import { ClickAwayListener } from '@mui/material'
import purchaseOrderService from 'shared/services/purchase-order-service'
import { BiExport } from 'react-icons/bi'
import { exportJsonToCsvCustomRows } from 'shared/util/helper'

const TableToolbar = (props) => {
  const {
    setSearchKey,
    setBookSearchKey,
    setContainerSearchKey,
    setFreightSearchKey,
    stage,
    setArrivalDate,
    setDeliveryDate,
    setLoadDate,
    setStage,
    end,
    handleFilterChange,
    start,
    fetchProject,
    setWarehouse,
    warehouse,
    arrivalWarehouse,
    setDeliveryDateTo,
    selectMonth,
    setSelectMonth,
    setDocumentSearchKey,
    documentSearchKey
  } = props
  const location = useLocation()
  const { loading, setLoading, userData, newParam, onChangeRouter, navigate } = useContext(UserContext)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [projectDetailSku, setProjectSkuDetails] = useState([])
  const [loadDateRange, setLoadDateRange] = useState({ start: '', end: '' })
  const [deliveryDateRange, setDeliveryDateRange] = useState({ start: '', end: '' })
  const [isLoadDatePickerOpen, setIsLoadDatePickerOpen] = useState(false)
  const [isDeliveryDatePickerOpen, setIsDeliveryDatePickerOpen] = useState(false)
  const toggleLoadDatePicker = () => setIsLoadDatePickerOpen((prevState) => !prevState)
  const toggleDeliveryDatePicker = () => setIsDeliveryDatePickerOpen((prevState) => !prevState)
  const closeLoadDatePicker = () => setIsLoadDatePickerOpen(false)
  const closeDeliveryDatePicker = () => setIsDeliveryDatePickerOpen(false)

  const handleLoadDateChange = (e) => {
    const { name, value } = e.target
    setLoadDateRange((prevRange) => ({
      ...prevRange,
      [name]: value
    }))
    if (name === 'start') {
      setLoadDate(value)
    } else if (name === 'end') {
      setArrivalDate(value)
    }
  }

  const handleDeliveryDateChange = (e) => {
    const { name, value } = e.target
    setDeliveryDateRange((prevRange) => ({
      ...prevRange,
      [name]: value
    }))
    if (name === 'start') {
      setDeliveryDate(value)
    } else if (name === 'end') {
      setDeliveryDateTo(value)
    }
  }

  const options = ['Waiting', 'Loading', 'In Transit', 'Arrived', 'Delivered']
  const monthOptions = ['Last Month', 'Last 3 months', 'Last 6 months', 'Last Year']
  const statusPo = [
    'Quotation',
    'Proposal',
    'Confirmed',
    'Partially Shipped',
    'Shipped',
    'Cancelled',
    'Request For Change'
  ]
  const ShipViafilter = ['Vessel', 'Truck']

  function validatedQty(id, productData) {
    const product = productData?.find((product) => product?.productId === id)
    return product?.validatedQty ? Number(product?.validatedQty?.qty ?? 0) : ''
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10))
    setPage(0)
  }

  const searchDeliveriesSku = async (value) => {
    setLoading(true)
    try {
      let payload = {
        sku: value
      }
      const queryParams = new URLSearchParams(value ? payload : '')
      const apiRes = await purchaseOrderService.getProjectDetailSearchSku(queryParams.toString())
      setProjectSkuDetails(apiRes.data)
    }
     catch (error) {
      console.log(error)
    } 
    finally {
      setLoading(false)
    }
  }
  const ExportPOSKuData = async (data) => {
    const rows = [['PO Number','Sku', 'Valid Quantity', 'Status']]
    if (Array.isArray(data)) {
      data?.map((item) => {
        rows.push([
          item?.poNumber ? item?.poNumber : '--',
          item?.products?.sku ? item?.products?.sku : '--',
          item?.products ? validatedQty(item?.products?.id, item?.products?.products) : 0,
          item?.status ? item?.status : '--'
        ])
      })
    }
    await exportJsonToCsvCustomRows(rows, 'Purchase SKU Reports')
  }
  const ExportSkuData = async (data) => {
    const rows = [['Project Id', 'Delivery Id', 'Sku', 'PO Number', 'Quantity', 'Warehouse', 'Status']]
    if (Array.isArray(data)) {
      data?.map((item) => {
        rows.push([
          item?.projectId ? item?.projectId : '--',
          item?.deliveryId ? item?.deliveryId : '--',
          item?.sku_detail.sku ? item?.sku_detail.sku : '--',
          item?.sku_detail.poNumber ? item?.sku_detail.poNumber : '--',
          item?.sku_detail.quantity ? item?.sku_detail.quantity : '--',
          item?.sku_detail.warehouse ? item?.sku_detail.warehouse : '--',
          item?.status ? item?.status : '--'
        ])
      })
    }
    await exportJsonToCsvCustomRows(rows, 'Project SKU Reports')
  }
  const onSearch = (value, type) => {
    if (type === 'Products') {
      props.searcProducthData(value)
      return true
    } else if (type === 'Po-Number') {
      setSearchKey(value.trim())
    } else if (type === 'ProjectID') {
      setSearchKey(value)
    } else if (type === 'Booking Number') {
      setBookSearchKey(value)
    } else if (type === 'Container Number') {
      setContainerSearchKey(value)
      return true
    } else if (type === 'Freight_line') {
      setFreightSearchKey(value)
    }
    else if (type === 'Project_Sku') {
      searchDeliveriesSku(value)
    }
  }
  const inputIds = ['project_id', 'booking_id', 'Freight_id', 'container_id','product' , 'po_id']
  const clearInputFields = (inputIds) => {
    inputIds.forEach(id => {
      const element = document.getElementById(id)
      if (element) {
        element.value = ""
      } else {
        console.warn(`Element with id "${id}" not found.`)
      }
    })
  }
  async function handleResetPorjectFilter() {
    await setWarehouse('')
    clearInputFields(inputIds)
    setSearchKey('')
    setBookSearchKey('')
    setContainerSearchKey('')
    setFreightSearchKey('')
    setStage('')
    setLoadDate('')
    setArrivalDate('')
    setDeliveryDate('')
    setDeliveryDateTo('')
    setSelectMonth('')
    setDocumentSearchKey('')
    setLoadDateRange({ start: '', end: '' })
    setDeliveryDateRange({ start: '', end: '' })
    fetchProject()
    navigate('/projects')
  }

  return (
    <>
      <Modal
        open={props.openProductModel}
        onClose={() => props.setOpenProductModel(false)}
        header={'Products'}
        fullWidth
        maxWidth='xl'
        content={
          location?.pathname == '/projects' ? (
            <>
              <div className='d-flex justify-content-between mt-3 mb-3'>
                <TextField
                  label='Products'
                  id='product'
                  variant='filled'
                  InputLabelProps={{ shrink: true }}
                  autoFocus={true}
                  input={<CustomisedInput />}
                  onChange={(e) => {
                    onSearch(e.target.value.toLocaleLowerCase().trim(), 'Project_Sku')
                  }}
                />
                {projectDetailSku.length ? (
                  <Grid item xs={12} sm={6} md={4} lg={1}>
                    <Button
                      className='rounded-3 fs-16'
                      variant='outlined'
                      onClick={() => {
                        ExportSkuData(projectDetailSku)
                      }}
                      style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                    >
                      <BiExport className='me-2' size='1.2rem' />
                      Export
                    </Button>
                  </Grid>
                ) : (
                  ''
                )}
              </div>
              <div style={{ overflowX: 'auto' }} className='mb-3'>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr className='order1-dataList' style={{ borderBottom: '1px solid #ccc' }}>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Project Id</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Delivery Id</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Sku</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>PO Number</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Quantity</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Warehouse</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Status</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <>
                      <tbody>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <tr key={index} style={{ marginBottom: '0', width: 100 }}>
                            <td colSpan='7' style={{ padding: '8px', textAlign: 'left' }}>
                              <Skeleton animation='wave' height={50} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <tbody>
                      {projectDetailSku.length ? (
                        projectDetailSku?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                          return (
                            <tr key={item?.id} style={{ borderBottom: '1px solid #ccc' }}>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                {item?.projectId ? item?.projectId : '--'}
                              </td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                {item?.deliveryId ? item?.deliveryId : '--'}
                              </td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                {item?.sku_detail.sku ? item?.sku_detail.sku : '--'}
                              </td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                {item?.sku_detail.poNumber ? item?.sku_detail.poNumber : '--'}
                              </td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                {item?.sku_detail.quantity ? item?.sku_detail.quantity : '--'}
                              </td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                {item?.sku_detail.warehouse ? item?.sku_detail.warehouse : '--'}
                              </td>
                              <td style={{ padding: '8px', textAlign: 'left' }}>
                                {item?.status ? item?.status : '--'}
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                          <td
                            colSpan='7'
                            style={{ fontSize: 20, padding: '50px', marginTop: '50px', textAlign: 'center' }}
                          >
                            No products available please search ...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
              {projectDetailSku?.length > 9 && (
                <TablePagination
                  className='mt-5'
                  rowsPerPageOptions={[5, 10, 25]}
                  component='div'
                  count={projectDetailSku?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  data-testid='table-pagination'
                />
              )}
            </>
          ) : (
            <>
              <div className='d-flex justify-content-between mt-3 mb-3'>
                <TextField
                  label='Products'
                  id='product'
                  variant='filled'
                  InputLabelProps={{ shrink: true }}
                  autoFocus={true}
                  input={<CustomisedInput />}
                  onChange={(e) => {
                    props.setSearch(true)
                    navigate('/purchaseorders')
                    onSearch(e.target.value.toLocaleLowerCase(), 'Products')
                  }}
                />
                {props?.productDetails?.length ? (
                  <Grid item xs={12} sm={6} md={4} lg={1}>
                    <Button
                      className='rounded-3 fs-16'
                      variant='outlined'
                      onClick={() => {
                        ExportPOSKuData(props?.productDetails)
                      }}
                      style={{ backgroundColor: '#424242', color: 'white', textTransform: 'none', fontSize: 16 }}
                    >
                      <BiExport className='me-2' size='1.2rem' />
                      Export
                    </Button>
                  </Grid>
                ) : (
                  ''
                )}
              </div>
              <div style={{ overflowX: 'auto' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr className='order1-dataList' style={{ borderBottom: '1px solid #ccc' }}>
                      <th style={{ padding: '8px', textAlign: 'left' }}>PO Number</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Image</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Sku</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Validated Qty</th>
                      <th style={{ padding: '8px', textAlign: 'left' }}>Status</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <>
                      <tbody>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <tr key={index} style={{ marginBottom: '0', width: 100 }}>
                            <td colSpan='7' style={{ padding: '8px', textAlign: 'left' }}>
                              <Skeleton animation='wave' height={50} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <tbody>
                      {props?.productDetails?.length ? (
                        props?.productDetails
                          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((item) => {
                            return (
                              <tr key={item?.id} style={{ borderBottom: '1px solid #ccc' }}>
                                <td style={{ padding: '8px', textAlign: 'left' }}>
                                  {item?.poNumber ? item?.poNumber : '--'}
                                </td>
                                <td style={{ padding: '8px', textAlign: 'left' }}>
                                  <Tooltip
                                    title={
                                      <img
                                        src={
                                          Array.isArray(item?.products?.images) && item?.products?.images
                                            ? item?.products?.images[0]
                                            : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                                        }
                                        alt='Product'
                                        width='200px'
                                      />
                                    }
                                    arrow
                                    placement='right'
                                    PopperProps={{ style: { marginLeft: '10px' } }}
                                  >
                                    <img
                                      src={
                                        Array.isArray(item?.products?.images) && item?.products?.images
                                          ? item?.products?.images[0]
                                          : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                                      }
                                      alt='Product'
                                      width='60px'
                                      height='60px'
                                    />
                                  </Tooltip>
                                </td>
                                <td style={{ padding: '8px', textAlign: 'left' }}>
                                  {item?.products?.sku ? item?.products?.sku : '-'}
                                </td>
                                <td style={{ padding: '8px', textAlign: 'left' }}>
                                  {item?.products ? validatedQty(item?.products?.id, item?.products?.products) : 0}
                                </td>
                                <td style={{ padding: '8px', textAlign: 'left' }}>
                                  {item?.status ? item?.status : '--'}
                                </td>
                              </tr>
                            )
                          })
                      ) : (
                        <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                          <td
                            colSpan='7'
                            style={{ fontSize: 20, padding: '50px', marginTop: '50px', textAlign: 'center' }}
                          >
                            No products available please search ...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>

              {props?.productDetails?.length > 9 && (
                <TablePagination
                  className='mt-5'
                  rowsPerPageOptions={[5, 10, 25]}
                  component='div'
                  count={props?.productDetails?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  data-testid='table-pagination'
                />
              )}
            </>
          )
        }
        handleClose={(e) => {
          e.preventDefault()
          setLoading(false)
          props.setOpenProductModel(false)
          if (location.pathname === '/projects') return setProjectSkuDetails([])
          props?.setProductDetails([])
          props.setFilterProductId([])
          props.getAllPurchaseRefetch()
        }}
      />
      {location?.pathname == '/purchaseorders' ? (
        <>
          <Grid container alignItems='center' spacing={2} sx={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} md={4} lg={1.5}>
              <Tooltip title='Search Purchase Order'>
                <FormControl fullWidth>
                  <TextField
                    label='Search Po-Number'
                    size='medium'
                    id='po_id'
                    variant='filled'
                    input={<CustomisedInput />}
                    onChange={(e) => onSearch(e?.target?.value.toLowerCase(), 'Po-Number')}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1.5}>
              <Tooltip title='Products..'>
                <FormControl fullWidth>
                  <TextField
                    label='Products'
                    size='medium'
                    id='product'
                    variant='filled'
                    input={<CustomisedInput />}
                    onClick={() => props.setOpenProductModel(true)}
                  />
                </FormControl>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant='filled'>
                <InputLabel id='demo-simple-select-filled-label'>PO Status</InputLabel>
                <Select
                  labelId='demo-simple-select-filled-label'
                  id='demo-simple-select-filled'
                  value={props.po}
                  onChange={props.handleFilterChange('po')}
                >
                  <MenuItem value=''>All</MenuItem>
                  {statusPo?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant='filled'>
                <InputLabel id='demo-simple-select-filled-label'>Ship Via</InputLabel>
                <Select
                  labelId='demo-simple-select-filled-label'
                  id='demo-simple-select-filled'
                  value={props.shipVia}
                  onChange={props.handleFilterChange('shipVia')}
                >
                  <MenuItem value=''>All</MenuItem>
                  {ShipViafilter?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant='filled' className='border-none'>
                <InputLabel id='demo-simple-select-filled-label'>Warehouse</InputLabel>
                <Select
                  labelId='demo-simple-select-filled-label'
                  id='demo-simple-select-filled'
                  value={props.warehouse}
                  onChange={props.handleFilterChange('warehouse')}
                >
                  <MenuItem value=''>All</MenuItem>
                  {arrivalWarehouse?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {userData?.user?.pomUserType === 1 ? (
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <FormControl fullWidth variant='filled' className='border-none'>
                  <InputLabel id='demo-simple-select-filled-label'>Supplier</InputLabel>
                  <Select
                    labelId='demo-simple-select-filled-label'
                    id='demo-simple-select-filled'
                    value={props.supplier}
                    onChange={props.handleFilterChange('supplier')}
                  >
                    <MenuItem value=''>All</MenuItem>
                    {props?.vendors ? (
                      props?.vendors?.map((option) => (
                        <MenuItem key={option.companyName} value={option.companyName}>
                          {option.companyName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value=''></MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <ClickAwayListener onClickAway={closeDeliveryDatePicker}>
                <Grid container alignItems='center' spacing={2} sx={{ position: 'relative' }}>
                  <Grid item xs={12}>
                    <Tooltip
                      title={start !== 'null' && end !== 'null' ? `Selected Date Range: ${start} to ${end}` : 'PO Date'}
                      arrow
                    >
                      <TextField
                        label='PO Date'
                        variant='filled'
                        value={start !== 'null' && end !== 'null' ? `${start} to ${end}` : ''}
                        onClick={toggleDeliveryDatePicker}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Tooltip>
                    {isDeliveryDatePickerOpen && (
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          marginTop: '10px',
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          position: 'absolute',
                          backgroundColor: 'white',
                          zIndex: 1300,
                          width: '200px',
                          maxWidth: '500px'
                        }}
                      >
                        <Grid item xs={12} className='p-2'>
                          <label style={{ display: 'block', marginBottom: '5px' }}>PO Date - From:</label>
                          <input
                            type='date'
                            name='start'
                            value={start}
                            onChange={handleFilterChange('start')}
                            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                          />
                        </Grid>
                        <Grid item xs={12} className='p-2'>
                          <label style={{ display: 'block', marginBottom: '5px' }}>PO Date - To:</label>
                          <input
                            type='date'
                            name='end'
                            value={end}
                            onChange={handleFilterChange('end')}
                            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                            disabled={start === 'null'}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={1}>
              <ClickAwayListener onClickAway={closeLoadDatePicker}>
                <Grid container alignItems='center' spacing={2} sx={{ position: 'relative' }}>
                  <Grid item xs={12}>
                    <Tooltip
                      title={
                        newParam.get('ready_start_date') || newParam.get('ready_end_date')
                          ? `Selected Date Range: ${newParam.get('ready_start_date')} to ${
                              newParam.get('ready_end_date') ? newParam.get('ready_end_date') : `∞`
                            }`
                          : 'Ready Date'
                      }
                      arrow
                    >
                      <TextField
                        label='Ready  Date'
                        variant='filled'
                        value={
                          newParam.get('ready_start_date') || newParam.get('ready_end_date')
                            ? `${newParam.get('ready_start_date')} to ${
                                newParam.get('ready_end_date') ? newParam.get('ready_end_date') : `∞`
                              }`
                            : ''
                        }
                        onClick={toggleLoadDatePicker}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Tooltip>
                    {isLoadDatePickerOpen && (
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          marginTop: '10px',
                          padding: '10px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          position: 'absolute',
                          backgroundColor: 'white',
                          zIndex: 1300,
                          width: '200px !important',
                          maxWidth: '500px'
                        }}
                      >
                        <Grid item xs={12} className='p-2'>
                          <label style={{ display: 'block', marginBottom: '5px' }}>Ready Date - From:</label>
                          <input
                            type='date'
                            name='start'
                            value={newParam.get('ready_start_date')}
                            onChange={(e) => onChangeRouter('ready_start_date', e.target.value)}
                            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                          />
                        </Grid>
                        <Grid item xs={12} className='p-2'>
                          <label style={{ display: 'block', marginBottom: '5px' }}>Ready Date - To:</label>
                          <input
                            type='date'
                            name='end'
                            value={newParam.get('ready_end_date')}
                            onChange={(e) => onChangeRouter('ready_end_date', e.target.value)}
                            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                            disabled={newParam.get('ready_start_date') === 'null'}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={1}>
              <Button
                sx={{ minHeight: 50 }}
                size='large'
                variant='outlined'
                onClick={() => {
                  props.getAllPurchaseRefetch()
                  // props.fetchPurchaseOrders()
                  props.setFilterProductId([])
                  setSearchKey('')
                  props.setPo('null')
                  props.setSupplier('null')
                  props.setWarehouse('null')
                  props.setStart('null')
                  props.setEnd('null')
                  props.setShipVia('null')
                  clearInputFields(inputIds)
                  navigate(`/purchaseorders`)
                }}
              >
                Reset
                <span>
                  <GrPowerReset className='reset-icon ms-2' size='1.2rem' />
                </span>
              </Button>
            </Grid>
          </Grid>
        </>
      ) : location.pathname == '/projects' ? (
        <Grid container alignItems='center' spacing={2} sx={{ marginBottom: '20px', flexWrap: 'nowrap' }}>
        <Grid item xs={1}>
          <Tooltip title='Project ID'>
            <FormControl size='small' variant='outlined' fullWidth>
              <TextField
                label='Project ID'
                size='medium'
                id='project_id'
                variant='filled'
                input={<CustomisedInput />}
                onChange={(e) => onSearch(e.target.value.toLowerCase(), 'ProjectID')}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        
        <Grid item xs={1}>
          <Tooltip title='Products..'>
            <FormControl size='small' variant='outlined' fullWidth>
              <TextField
                label='Products'
                size='medium'
                id='product'
                variant='filled'
                input={<CustomisedInput />}
                onClick={() => props.setOpenProductModel(true)}
              />
            </FormControl>
          </Tooltip>
        </Grid>
      
        <Grid item xs={1}>
          <Tooltip title='Search Booking Number'>
            <FormControl variant='filled' fullWidth>
              <TextField
                label='Booking Number'
                size='medium'
                variant='filled'
                id='booking_id'
                input={<CustomisedInput />}
                onChange={(e) => onSearch(e.target.value.toLowerCase(), 'Booking Number')}
              />
            </FormControl>
          </Tooltip>
        </Grid>
      
        <Grid item xs={1}>
          <Tooltip title='Search Container Number'>
            <FormControl variant='filled' fullWidth>
              <TextField
                label='Container Number'
                size='medium'
                id='container_id'
                variant='filled'
                input={<CustomisedInput />}
                onChange={(e) => onSearch(e.target.value.toLowerCase(), 'Container Number')}
              />
            </FormControl>
          </Tooltip>
        </Grid>
      
        <Grid item xs={1}>
          <Tooltip title='Search Freight Number'>
            <FormControl variant='filled' fullWidth>
              <TextField
                label='Freight Line'
                size='medium'
                variant='filled'
                id='Freight_id'
                input={<CustomisedInput />}
                onChange={(e) => onSearch(e.target.value.toLowerCase(), 'Freight_line')}
              />
            </FormControl>
          </Tooltip>
        </Grid>
      
        <Grid item xs={1}>
          <FormControl fullWidth variant='filled'>
            <InputLabel id='stage-select-label'>Stage</InputLabel>
            <Select
              labelId='stage-select-label'
              id='stage-select'
              value={stage}
              onChange={(e) => setStage(e.target.value)}
            >
              <MenuItem value=''>All</MenuItem>
              {options?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      
        <Grid item xs={1}>
          <FormControl fullWidth variant='filled'>
            <InputLabel id='warehouse-select-label'>Warehouse</InputLabel>
            <Select
              labelId='warehouse-select-label'
              id='warehouse-select'
              value={warehouse}
              onChange={(e) => setWarehouse(e?.target?.value)}
            >
              <MenuItem value=''>All</MenuItem>
              {arrivalWarehouse?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      
        <Grid item xs={1}>
          <FormControl fullWidth variant='filled'>
            <InputLabel id='month-select-label'>Time Period</InputLabel>
            <Select
              labelId='month-select-label'
              id='month-select'
              value={selectMonth}
              onChange={(e) => setSelectMonth(e.target.value)}
            >
              <MenuItem value=''>All</MenuItem>
              {monthOptions?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      
        <Grid item xs={1}>
          <FormControl fullWidth variant='filled'>
            <InputLabel id='document-select-label'>Document</InputLabel>
            <Select
              labelId='document-select-label'
              id='document-select'
              value={documentSearchKey}
              onChange={(e) => setDocumentSearchKey(e.target.value)}
            >
              <MenuItem value='yes'>YES</MenuItem>
              <MenuItem value='no'>NO</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      
        <Grid item xs={1}>
          <ClickAwayListener onClickAway={closeLoadDatePicker}>
            <Grid container alignItems='center' spacing={2} sx={{ position: 'relative' }}>
              <Grid item xs={12}>
                <Tooltip
                  title={
                    loadDateRange.start && loadDateRange.end
                      ? `Selected Date Range: ${loadDateRange.start} to ${loadDateRange.end}`
                      : 'Load Date'
                  }
                  arrow
                >
                  <TextField
                    label='Load Date'
                    variant='filled'
                    value={
                      loadDateRange.start && loadDateRange.end
                        ? `${loadDateRange.start} to ${loadDateRange.end}`
                        : ''
                    }
                    onClick={toggleLoadDatePicker}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Tooltip>
                {isLoadDatePickerOpen && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: '10px',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      position: 'absolute',
                      backgroundColor: 'white',
                      zIndex: 1300,
                      width: '200px',
                      maxWidth: '500px'
                    }}
                  >
                    <Grid item xs={12} className='p-2'>
                      <label style={{ display: 'block', marginBottom: '5px' }}>Load Date - From:</label>
                      <input
                        type='date'
                        name='start'
                        value={loadDateRange.start}
                        onChange={handleLoadDateChange}
                        style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                      />
                    </Grid>
                    <Grid item xs={12} className='p-2'>
                      <label style={{ display: 'block', marginBottom: '5px' }}>Load Date - To:</label>
                      <input
                        type='date'
                        name='end'
                        value={loadDateRange.end}
                        onChange={handleLoadDateChange}
                        style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </ClickAwayListener>
        </Grid>
      
        <Grid item xs={1}>
          <ClickAwayListener onClickAway={closeDeliveryDatePicker}>
            <Grid container alignItems='center' spacing={2} sx={{ position: 'relative' }}>
              <Grid item xs={12}>
                <Tooltip
                  title={
                    deliveryDateRange.start && deliveryDateRange.end
                      ? `Selected Date Range: ${deliveryDateRange.start} to ${deliveryDateRange.end}`
                      : 'Delivery Date'
                  }
                  arrow
                >
                  <TextField
                    label='Delivery Date'
                    variant='filled'
                    value={
                      deliveryDateRange.start && deliveryDateRange.end
                        ? `${deliveryDateRange.start} to ${deliveryDateRange.end}`
                        : ''
                    }
                    onClick={toggleDeliveryDatePicker}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Tooltip>
                {isDeliveryDatePickerOpen && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: '10px',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      position: 'absolute',
                      backgroundColor: 'white',
                      zIndex: 1300,
                      width: '200px',
                      maxWidth: '500px'
                    }}
                  >
                    <Grid item xs={12} className='p-2'>
                      <label style={{ display: 'block', marginBottom: '5px' }}>Delivery Date - From:</label>
                      <input
                        type='date'
                        name='start'
                        value={deliveryDateRange.start}
                        onChange={handleDeliveryDateChange}
                        style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                      />
                    </Grid>
                    <Grid item xs={12} className='p-2'>
                      <label style={{ display: 'block', marginBottom: '5px' }}>Delivery Date - To:</label>
                      <input
                        type='date'
                        name='end'
                        value={deliveryDateRange.end}
                        onChange={handleDeliveryDateChange}
                        style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </ClickAwayListener>
        </Grid>
      
        <Grid item xs={1}>
          <ProductButton
            fullWidth
            sx={{ height: 50 }}
            variant='outlined'
            onClick={() => {
              handleResetPorjectFilter()
            }}
          >
            Reset{' '}
            <span>
              <GrPowerReset className='reset-icon ms-2' size='1.2rem' />
            </span>
          </ProductButton>
        </Grid>
      </Grid>
      
      ) : (
        <Grid container direction='row' justifyContent='space-between' alignItems='center' className='my-3'>
          <Grid item>
            <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
              <Grid item>
                <Tooltip title='Search Po Number'>
                  <FormControl sx={{ m: 1, minWidth: 80 }} size='small' variant='outlined'>
                    <TextField
                      label='Search Po Number'
                      size='medium'
                      variant='filled'
                      input={<CustomisedInput />}
                      onChange={(e) => {
                        setSearchKey(e.target.value.toLowerCase())
                      }}
                    />
                  </FormControl>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setSearchKey: PropTypes.func,
  searchKey: PropTypes.string.isRequired,
  setBookSearchKey: PropTypes.func,
  setContainerSearchKey: PropTypes.func,
  setDocumentSearchKey: PropTypes.func,
  documentSearchKey: PropTypes.string,
  setFreightSearchKey: PropTypes.func,
  setSortOn: PropTypes.func,
  title: PropTypes.string,
  setSortOrder: PropTypes.func,
  sortOrder: PropTypes.number,
  sortOn: PropTypes.string,
  items: PropTypes.array,
  showReviewList: PropTypes.bool,
  setDraftBtn: PropTypes.func,
  draftBtn: PropTypes.bool,
  setPo: PropTypes.func,
  setSupplier: PropTypes.func,
  setWarehouse: PropTypes.func,
  setStart: PropTypes.func,
  setEnd: PropTypes.func,
  setShipVia: PropTypes.func,
  setWarehouse: PropTypes.func,
  setStart: PropTypes.func,
  setEnd: PropTypes.func,
  handleFilterChange: PropTypes.func,
  fetchFilterData: PropTypes.func,
  fetchPurchaseOrders: PropTypes.func,
  supplier: PropTypes.string,
  po: PropTypes.string,
  getAllPurchaseRefetch: PropTypes.func,
  warehouse: PropTypes.string,
  arrivalWarehouse: PropTypes.arrayOf().isRequired,
  shipVia: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  vendors: PropTypes.array,
  stage: PropTypes.string,
  loadDate: PropTypes.string,
  arrivalDates: PropTypes.string,
  setArrivalDate: PropTypes.func,
  deliveryDate: PropTypes.string,
  setDeliveryDate: PropTypes.func,
  deliveryDateTo: PropTypes.string,
  setDeliveryDateTo: PropTypes.func,
  selectMonth: PropTypes.string,
  setSelectMonth: PropTypes.func,
  setLoadDate: PropTypes.func,
  setStage: PropTypes.func,
  fetchProject: PropTypes.func,
  searcProducthData: PropTypes.func,
  isSearch: PropTypes.bool,
  setSearch: PropTypes.func,
  productDetails: PropTypes.array,
  setProductDetails: PropTypes.func,
  setFilterProductId: PropTypes.func,
  openProductModel: PropTypes.bool,
  setOpenProductModel: PropTypes.func,
}

export default TableToolbar
