import React, { useRef } from 'react'
import {
  Grid,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import { getDate } from 'shared/util/helper'

const RefundTable = (props) => {
  const {
    returnList,
    openStatus,
    handleOpenDialogBox,
    status,
    handleChange,
    updateStatus,
    orderNumber,
    setOrderNumber
  } = props
  const tableContainerRef = useRef(null)

  return (
    <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
      <TableContainer className='scroll-box' ref={tableContainerRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Order Number</strong>
              </TableCell>
              <TableCell>
                <strong>Sku</strong>
              </TableCell>
              <TableCell>
                <strong>Country</strong>
              </TableCell>
              

              <TableCell>
                <strong>Status</strong>
              </TableCell>
              <TableCell>
                <strong>Collection Date</strong>
              </TableCell>
              <TableCell>
                <strong>Reason</strong>
              </TableCell>
              <TableCell>
                <strong>Action</strong>
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(returnList) && returnList.length ? (
              returnList.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item?.orderNumber ? item?.orderNumber : '--'}</TableCell>
                  <TableCell>{item?.sku ? item?.sku : '--'}</TableCell>
                  <TableCell> {item?.country ? item?.country : '--'}</TableCell>
                  <TableCell>{item?.status.length ? item?.status[item?.status.length - 1]?.status : 'N/A'}</TableCell>
                  <TableCell> {item?.collectionDate?.length ? getDate(item?.collectionDate) : '--'}</TableCell>

                  <TableCell> {item?.reason ? item?.reason : '--'}</TableCell>

                  <TableCell>
                    <IconButton
                      size='small'
                      sx={{ ml: 1 }}
                      onClick={() => {
                        handleOpenDialogBox()
                        setOrderNumber(item?.orderNumber)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>

                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 30,
                      marginBottom: 30
                    }}
                  >
                    <p className='text-center'>No Records Found... </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openStatus}
        onClose={handleOpenDialogBox}
        PaperProps={{
          sx: {
            borderRadius: 4,
            padding: 2,
            minWidth: 350
          }
        }}
      >
        <DialogTitle>Update the status</DialogTitle>
        <DialogContent sx={{ minWidth: 300 }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='demo-simple-select-helper-label'>Status</InputLabel>
            <Select
              labelId='demo-simple-select-helper-label'
              id='demo-simple-select-helper'
              value={status}
              label='Age'
              onChange={handleChange}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              <MenuItem value={'generated'}>Generated</MenuItem>
              <MenuItem value={'processing'}>Processing</MenuItem>
              <MenuItem value={'confirmed'}>Confirmed</MenuItem>
              <MenuItem value={'intransit'}>In Transit</MenuItem>
              <MenuItem value={'Completed'}>Completed</MenuItem>
            </Select>
            <FormHelperText>Select the Current Status of Retrun </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button onClick={handleOpenDialogBox} variant='outlined' size='small' sx={{ borderRadius: '8px' }}>
            Cancel
          </Button>
          <Button
            onClick={() => updateStatus(orderNumber, status)}
            variant='outlined'
            size='small'
            sx={{ borderRadius: '8px' }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
RefundTable.propTypes = {
  skuData: PropTypes.array,
  skupage: PropTypes.number,
  openStatus: PropTypes.bool,
  handleOpenDialogBox: PropTypes.func,
  handleSkuChangeRowsPerPage: PropTypes.func,
  returnList: PropTypes.array,
  status: PropTypes.string,
  handleChange: PropTypes.func,
  updateStatus: PropTypes.func,
  orderNumber: PropTypes.string,
  setOrderNumber: PropTypes.func
}

export default RefundTable
