import React from 'react'
import { Box } from '@mui/material'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

const boxStyles = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 1,
    m: 1,
    bgcolor: theme.palette.mode === 'dark' ? '#101010' : '#fff',
    color: theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
    borderRadius: 2,
    fontSize: '0.875rem',
    fontWeight: '700',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
})

const CommonBox = (props) => {
    const { title, amount, difference, disabled, isSales,lastamount } = props
    const theme = useTheme()
    const amountNum = parseFloat(lastamount)
    const differenceNum = parseFloat(difference)
    const percentageChange = !isNaN(amountNum) && !isNaN(differenceNum) && amountNum !== 0 ? ((differenceNum / amountNum) * 100).toFixed(2) : 0
    const isIncrease = parseFloat(percentageChange) < 0
    return (
        <>
            <Box component='div' sx={{
                ...boxStyles(theme),
                backgroundColor: isIncrease ? '#D25E5E' : '#34C085',

            }}>
                <span style={{ fontSize: '23px', color: 'white' }}>
                {amount && `${isSales ? '€' : ''}${isSales ? amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : amount.toLocaleString('en-US')}`}
            </span>
            <span style={{ fontSize: '12px', color: 'white' }}>
                {title}{' '}
                <span style={{ color: isIncrease ? 'red' : 'green' }}>
                    {isIncrease ? <TiArrowSortedDown color='#ca4646' /> : <TiArrowSortedUp color='#249968' />} {disabled ? '' : <span style={{ color: 'white' }}>{`${percentageChange}%`}</span>}
                </span>
                </span>
            </Box>
        </>
    )
}
CommonBox.propTypes = {
    title: PropTypes.string.isRequired,
    amount: PropTypes.number,
    difference: PropTypes.number.isRequired,
    isDecreasing: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isSales: PropTypes.bool.isRequired,
    lastamount: PropTypes.number,

}
export default CommonBox