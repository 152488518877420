import React, { useState } from 'react'
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Grid,
  Button,
  Tooltip,
  Typography,
  Stack,
  Chip,
  Collapse
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { GrPowerReset } from 'react-icons/gr'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { UserContext } from 'context/AuthContext/UserContext'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'

const DrawerList = ({
  warehouseData,
  groupedShops,
  shipmentData,
  uniqueVendors,
  handleMonthsChange,
  getSelectedMonths,
  monthsOptions
}) => {
  const { newParam, onChangeRouter, navigate } = useContext(UserContext)
  const selectedMonths = getSelectedMonths()
  const [expandedPrefix, setExpandedPrefix] = useState(null)
  const handlePrefixToggle = (prefix) => {
    setExpandedPrefix(expandedPrefix === prefix ? null : prefix)
  }

  return (
    <Box
      sx={{
        width: 300,
        padding: '20px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#FFF',
        borderRadius: '10px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          borderRadius: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#b9b9b9',
          borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#b9b9b9',
          borderRadius: '10px',
          border: '2px solid #f5f5f5'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555',
          borderRadius: '10px'
        },
        '& *': {
          scrollbarWidth: 'thin',
          scrollbarColor: '#b9b9b9 #f5f5f5'
        }
      }}
    >
      <Typography
        variant='h6'
        sx={{
          textAlign: 'center',
          fontWeight: 600,
          marginBottom: '20px',
          fontSize: '1.2rem',
          color: '#333'
        }}
      >
        Apply Filters
      </Typography>
      <Box sx={{ flex: 1, overflowY: 'auto', marginBottom: '20px', paddingRight: '16px' }}>
        <Grid container direction='column' spacing={3}>
          <Grid item>
            <FormControl
              fullWidth
              sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
              size='small'
              className='border-none'
            >
              <Select
                multiple
                displayEmpty
                IconComponent={() => null}
                value={selectedMonths}
                onChange={handleMonthsChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span>Select Multiple Months</span>
                  }
                  const displayCount = 1
                  const hiddenCount = selected.length - displayCount
                  return (
                    <Stack gap={1} direction='row' flexWrap='wrap'>
                      {selected.slice(0, displayCount).map((value) => (
                        <Chip
                          key={value}
                          label={monthsOptions.find((month) => month.value === value)?.label}
                          onDelete={() =>
                            handleMonthsChange({ target: { value: selected.filter((item) => item !== value) } })
                          }
                          deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                        />
                      ))}
                      {hiddenCount > 0 && <Chip label={`+${hiddenCount} more`} />}
                    </Stack>
                  )
                }}
              >
                <MenuItem value='all'>All Months</MenuItem>
                {monthsOptions.map((month) => (
                  <MenuItem key={month.value} value={month.value} sx={{ justifyContent: 'space-between' }}>
                    {month.label}
                    {selectedMonths.includes(month.value) ? <CheckIcon color='info' /> : null}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
              <Select
                value={newParam.get('month_quarter') || ''}
                displayEmpty
                onChange={(e) => onChangeRouter('month_quarter', e.target.value)}
                sx={{
                  '& .MuiSelect-select': { padding: '10px 12px' },
                  color: '#333'
                }}
              >
                <MenuItem value=''>Months OR Quarter</MenuItem>
                <MenuItem value='month_wise'>Months</MenuItem>
                <MenuItem value='quarter_wise'>Quarter</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Tooltip title={'Search Sku'}>
              <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
                <TextField
                  placeholder='Sku or Design'
                  size='small'
                  value={newParam.get('sku') || ''}
                  onChange={(e) => onChangeRouter('sku', e?.target?.value.trim())}
                  sx={{
                    '& .MuiInputBase-input': { padding: '10px 12px' },
                    color: '#333'
                  }}
                />
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid item >
            <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
              <Select
                value={newParam.get('shops') || ''}
                displayEmpty
                onChange={(e) => onChangeRouter('shops', e.target.value)}
                renderValue={(selected) => {
                  if (!selected) return 'Shops'
                  const foundShop = Object.values(groupedShops)
                    .flat()
                    .find((shop) => shop === selected)
                  return foundShop || selected
                }}
                sx={{
                  '& .MuiSelect-select': { padding: '10px 12px' },
                  color: '#333'
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 400
                    }
                  }
                }}
              >
                <MenuItem value=''>Shops</MenuItem>
                {Object.keys(groupedShops).map((prefix) => {
                  const shops = groupedShops[prefix]
                  if (shops.length === 1) {
                    return (
                      <MenuItem
                        key={shops[0]}
                        value={shops[0]}
                        selected={newParam.get('shops') === shops[0]}
                        onClick={() => onChangeRouter('shops', shops[0])}
                      >
                        {shops[0]}
                      </MenuItem>
                    )
                  } else {
                    return (
                      <React.Fragment key={prefix}>
                        <MenuItem
                          onClick={() => handlePrefixToggle(prefix)}
                          sx={{ fontWeight: 'bold', justifyContent: 'space-between' }}
                        >
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {prefix === 'Mark' ? 'Mark & Day' : prefix}
                          </Typography>
                          <ExpandMoreIcon
                            sx={{
                              transition: 'transform 0.2s',
                              transform: expandedPrefix === prefix ? 'rotate(180deg)' : 'rotate(0deg)'
                            }}
                          />
                        </MenuItem>
                        <Collapse in={expandedPrefix === prefix} timeout='auto' unmountOnExit>
                          {shops.map((shop) => (
                            <MenuItem
                              key={shop}
                              value={shop}
                              sx={{ pl: 4 }}
                              selected={newParam.get('shops') === shop}
                              onClick={() => onChangeRouter('shops', shop)}
                            >
                              {shop}
                            </MenuItem>
                          ))}
                        </Collapse>
                      </React.Fragment>
                    )
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
              <Select
                value={newParam.get('shipment_country') || ''}
                displayEmpty
                onChange={(e) => onChangeRouter('shipment_country', e.target.value)}
                renderValue={(selected) => {
                  if (!selected) {
                    return <span>Shipment Country</span>
                  }
                  return selected
                }}
                sx={{
                  '& .MuiSelect-select': { padding: '10px 12px' },
                  color: '#333'
                }}
              >
                <MenuItem value=''>Shipment Country</MenuItem>
                {shipmentData?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
              <Select
                value={newParam.get('warehouse') || ''}
                displayEmpty
                onChange={(e) => onChangeRouter('warehouse', e.target.value)}
                sx={{
                  '& .MuiSelect-select': { padding: '10px 12px' },
                  color: '#333'
                }}
              >
                <MenuItem value=''>Warehouse</MenuItem>
                {warehouseData?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
              <Select
                value={newParam.get('shop_type') || ''}
                displayEmpty
                onChange={(e) => onChangeRouter('shop_type', e.target.value)}
                sx={{
                  '& .MuiSelect-select': { padding: '10px 12px' },
                  color: '#333'
                }}
              >
                <MenuItem value=''>Shop Type</MenuItem>
                <MenuItem value='B2B'>B2B</MenuItem>
                <MenuItem value='B2C'>B2C</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
              <Select
                value={newParam.get('status') || ''}
                displayEmpty
                onChange={(e) => onChangeRouter('status', e.target.value)}
                sx={{
                  '& .MuiSelect-select': { padding: '10px 12px' },
                  color: '#333'
                }}
              >
                <MenuItem value=''>Status</MenuItem>
                <MenuItem value='closed'>Closed</MenuItem>
                <MenuItem value='shipped'>Shipped</MenuItem>
                <MenuItem value='returned'>Returned</MenuItem>
                <MenuItem value='cancelled'>Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth size='small' sx={{ backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
              <Select
                value={newParam.get('vendor') || ''}
                displayEmpty
                onChange={(e) => onChangeRouter('vendor', e.target.value)}
                renderValue={(selected) => {
                  if (!selected) {
                    return <span>Vendor</span>
                  }
                  return selected
                }}
                sx={{
                  '& .MuiSelect-select': { padding: '10px 12px' },
                  color: '#333'
                }}
              >
                <MenuItem value=''>Vendor</MenuItem>
                {uniqueVendors?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              fullWidth
              sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
              size='small'
              className='border-none'
            >
              <Select
                value={newParam.get('commerce_type')}
                displayEmpty
                InputLabelProps={{ shrink: false }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Commerce Type'
                  }
                  const menuItems = [
                    { value: 'ecomm', label: 'Ecomm' },
                    { value: 'false', label: 'Non Ecomm' }
                  ]

                  const selectedItem = menuItems.find((item) => item.value === selected)
                  return selectedItem ? selectedItem.label : 'Commerce Type'
                }}
                onChange={(e) => onChangeRouter('commerce_type', e.target.value)}
                IconComponent={() => null}
              >
                <MenuItem value=''>Commerce Type</MenuItem>
                <MenuItem value='ecomm'>Ecomm</MenuItem>
                <MenuItem value='false'>Non Ecomm</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Button
        variant='contained'
        onClick={() => {
          navigate('/salesreport')
        }}
        sx={{
          backgroundColor: '#424242',
          color: 'white',
          padding: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#333'
          }
        }}
        startIcon={<GrPowerReset size='1.0rem' />}
      >
        Reset Filters
      </Button>
    </Box>
  )
}

DrawerList.propTypes = {
  toggleDrawer: PropTypes.func,
  warehouseData: PropTypes.array,
  warehouseData: PropTypes.array,
  groupedShops: PropTypes.array,
  shipmentData: PropTypes.array,
  uniqueVendors: PropTypes.array,
  handleMonthsChange: PropTypes.func,
  getSelectedMonths: PropTypes.func,
  monthsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  )
}

export default DrawerList
