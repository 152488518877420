import { Grid, TableRow, TableCell, Table, TableHead, TableBody, TableContainer } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import SourceIcon from '@mui/icons-material/Source'
import { FaCalendar } from 'react-icons/fa'
import { FaUserTie } from 'react-icons/fa'
import { FaTruck } from 'react-icons/fa'
import { FaAddressCard } from 'react-icons/fa'

function OrderHeader(props) {
  const purchaseOrderDetail = props?.purchaseOrderDetail
  const { mobile, ...shippingAddress } = purchaseOrderDetail?.shipping_address

  // function joinObjectKeyValuePairsToArray(obj) {
  //     return Object.entries(obj)
  //         .map(([key, value]) => `${key}: ${value}`)
  // }

  // const billingAddressArray = joinObjectKeyValuePairsToArray(purchaseOrderDetail.billing_address)
  // const shippingAddressArray = joinObjectKeyValuePairsToArray(purchaseOrderDetail.shipping_address)

  return (
    <section>
      <div className='row mt-4'>
        <div className='col-md-12'>
          <div className='common-card rounded-3'>
            <div className='common-card-title'>
              <div className='d-flex align-items-center justify-content-between'>
                <h4>
                  <span className='order_four'>Order number: </span>
                  <span className='order_five'>{purchaseOrderDetail?.order_id}</span>
                </h4>
              </div>
            </div>
            <div className='common-card-content border-0 mt-0 mb-0'>
              <div className='p-1'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <Grid container spacing={2} className='order_seven'>
                        <Grid item xs={2}>
                          <i>
                            <SourceIcon style={{ fontSize: '40px' }} />
                          </i>
                        </Grid>
                        <Grid item xs={10}>
                          <h5 className='order_eight'>Source:</h5>
                          <p>{purchaseOrderDetail?.source}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={2} className='order_seven'>
                        <Grid item xs={2}>
                          <i>
                            <FaCalendar size={'2rem'} />
                          </i>
                        </Grid>
                        <Grid item xs={10}>
                          <h5 className='order_eight'>Creation date:</h5>
                          <p>{purchaseOrderDetail?.order_date}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={2} className='order_seven'>
                        <Grid item xs={2}>
                          <i>
                            <FaTruck size={'2rem'} />
                          </i>
                        </Grid>
                        <Grid item xs={10}>
                          <h5 className='order_eight'>State:</h5>
                          <p style={{ textTransform: 'capitalize' }}>{purchaseOrderDetail?.state}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={2} className='order_seven'>
                        <Grid item xs={2}>
                          <i>
                            <FaUserTie size={'2rem'} />
                          </i>
                        </Grid>
                        <Grid item xs={10}>
                          <h5 className='order_eight'>Customer:</h5>
                          <p className='order_nine'>{purchaseOrderDetail?.shipping_address?.name}</p>
                          {mobile}
                          <br />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <h5>Billing Address</h5>
                        </Grid>
                        <Grid item xs={6}>
                          <p>{billingAddressArray.join(', ')}</p>
                        </Grid>
                      </Grid>
                    </Grid> */}
                    <Grid item xs={4}>
                      <Grid container spacing={2} className='order_seven'>
                        <Grid item xs={2}>
                          <i>
                            <FaAddressCard size={'2rem'} />
                          </i>
                        </Grid>
                        <Grid item xs={10}>
                          <h5 className='order_eight'>Shipping address:</h5>
                          {Object.values(shippingAddress).join(', ')}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <h5 style={{ marginBottom: "0px", fontSize: "16px" }}>Tracking Details</h5>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ul>
                                            {purchaseOrderDetail.tracking_details.map((tracking, index) => (
                                                <li key={index}>
                                                    <p>
                                                        <strong>Tracking Code:</strong> {tracking.tracking_code}<br />
                                                        <strong>Carrier:</strong> {tracking.carrier}<br />
                                                        <strong>Shipped Date:</strong> {tracking.shipped_date}<br />
                                                        <strong>Tracking URL:</strong> <a href={tracking.tracking_url} target="_blank" rel="noopener noreferrer">Track</a>
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-12'>
          <div className='common-card rounded-3'>
            <div className='common-card-title'>
              <div className='d-flex align-items-center justify-content-between'>
                <h4>Products List</h4>
              </div>
            </div>
            <div className='common-card-content border-0 mt-0 mb-0'>
              <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong style={{ fontSize: 16 }}>Sr.No.</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontSize: 16 }}>SKU</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontSize: 16 }}>Quantity</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontSize: 16 }}>Price</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(purchaseOrderDetail.order_lines) && purchaseOrderDetail.order_lines.length ? (
                        purchaseOrderDetail.order_lines.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}.</TableCell>
                            <TableCell>{item?.sku ? item?.sku : '--'}</TableCell>
                            <TableCell>{item?.qty ? item?.qty : '--'}</TableCell>
                            <TableCell> {item?.price ? item?.price : '--'}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 30,
                                marginBottom: 30
                              }}
                            >
                              <p className='text-center'>No Records Found... </p>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-12'>
          {purchaseOrderDetail?.fulfillments && purchaseOrderDetail?.fulfillments?.length ? (
            <div className='common-card rounded-3'>
              <div className='common-card-content border-0 mt-0 mb-0'>
                <div className='px-3 pb-4'>
                  <div>
                    <h5 style={{ paddingBottom: '10px' }}>Fulfillment #1</h5>
                    <p className='fw-bold mb-3'>Fulfillment info:</p>
                    <p style={{ marginBottom: '45px', fontSize: '1rem' }}>
                      State&nbsp;-&nbsp;
                      <span className='order_fourteen'>{purchaseOrderDetail?.state} </span>
                    </p>
                    <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'}>
                      <TableContainer>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <strong style={{ fontSize: 16 }}>Sr.No.</strong>
                              </TableCell>
                              <TableCell>
                                <strong style={{ fontSize: 16 }}>SKU</strong>
                              </TableCell>
                              <TableCell>
                                <strong style={{ fontSize: 16 }}>Quantity</strong>
                              </TableCell>
                              <TableCell>
                                <strong style={{ fontSize: 16 }}>Tracking Code</strong>
                              </TableCell>
                              <TableCell>
                                <strong style={{ fontSize: 16 }}>Tracking Status</strong>
                              </TableCell>
                              <TableCell>
                                <strong style={{ fontSize: 16 }}>Carrier Name</strong>
                              </TableCell>
                              <TableCell>
                                <strong style={{ fontSize: 16 }}>Carrier Code</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Array.isArray(purchaseOrderDetail?.fulfillments) &&
                            purchaseOrderDetail?.fulfillments?.length ? (
                              purchaseOrderDetail?.fulfillments.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}.</TableCell>
                                  <TableCell>{item?.sku ? item?.sku : '--'}</TableCell>
                                  <TableCell>{item?.qty ? item?.qty : '--'}</TableCell>
                                  <TableCell>
                                    {' '}
                                    <button className='tracking_code'>
                                      <a
                                        href={`https://apc-overnight.com/TrackConsignment.php?parcel_no=${item.tracking_code}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        style={{ color: '#000', textDecoration: 'none' }}
                                      >
                                        {item.tracking_code}
                                      </a>
                                    </button>
                                  </TableCell>
                                  <TableCell>{item?.tracking_status ? item?.tracking_status : '--'}</TableCell>
                                  <TableCell>{item?.carrier_name ? item?.carrier_name : '--'}</TableCell>
                                  <TableCell> {item?.carrier_code ? item?.carrier_code : '--'}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={6}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      marginTop: 30,
                                      marginBottom: 30
                                    }}
                                  >
                                    <p className='text-center'>No Records Found... </p>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  )
}

OrderHeader.propTypes = {
  purchaseOrderDetail: PropTypes.object
}

export default OrderHeader
