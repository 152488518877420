import React from 'react'
import { Grid, Box, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import CommonBox from './CommonBox'

const getFullForm = (title) => {
    switch (title) {
        case 'WTD Sales':
            return 'Week to Date Untaxed Total'
        case 'YTD Sales':
            return 'Year to Date Untaxed Total'
        case 'MTD Sales':
            return 'Month to Date Untaxed Total'
        case 'WTD Units':
            return 'Week to Date Units'
        case 'YTD Units':
            return 'Year to Date Units'
        case 'MTD Units':
            return 'Month to Date Units'
        default:
            return title
    }
}

const DataBoxGrid = (props) => {
    const { data, rangeData, showRangeFilter, rangeFilterTitle } = props
    return (
        <>
            <Grid container spacing={2} alignItems="center">
                {data.map((item, index) => {
                    return (
                        <Grid item xs={4} key={index}>
                            <Tooltip title={getFullForm(item.title)} arrow>
                                <div>
                                    <CommonBox
                                        title={item.title}
                                        amount={item.amount}
                                        lastamount={item.lastamount}
                                        difference={item.difference}
                                        disabled={item.disabled}
                                        isSales={item.isSales}
                                    />
                                </div>
                            </Tooltip>
                        </Grid>
                    )
                })}
            </Grid>
            {showRangeFilter && (
                <Box>
                    <h5 className='ms-3 mt-2'>{rangeFilterTitle}</h5>
                    <Grid container spacing={2} alignItems='center'>
                        {rangeData.map((item, index) => (
                            <Grid item xs={4} key={index}>
                                <Tooltip title={getFullForm(item.title)} arrow>
                                    <div>
                                        <CommonBox
                                            title={item.title}
                                            amount={item.amount}
                                            lastamount={item.lastamount}
                                            difference={item.difference}
                                            disabled={item.disabled}
                                            isSales={item.isSales}
                                        />
                                    </div>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </>
    )
}


DataBoxGrid.propTypes = {
    data: PropTypes.array.isRequired,
    rangeData: PropTypes.array,
    showRangeFilter: PropTypes.bool,
    rangeFilterTitle: PropTypes.string
}

export default DataBoxGrid
