import apiClient from 'api/apiClient'
import apiClientHnm from 'api/apiClientHnm'
import { endpoints } from 'api/endpoints'
import odooClient from 'api/odooClient'

const purchaseOrderService = {
  addPurchaseOrder: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_PURCHASE_ORDER, input)
      return response
    } catch (error) {
      throw error
    }
  },

  publishPurchaseOrder: async (input, type = process.env.REACT_APP_ODOO_API_KEY) => {
    try {
      const response = await odooClient.post(endpoints.CREATE_ODOO_PURCHASE_ORDER, input, { type })
      return response
    } catch (error) {
      throw error
    }
  },

  getPurchaseOrder: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_COMPANY_PURCHASE_ORDER(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getLastPurchaseOrder: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_LAST_PO(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  filterPurchaseOrder: async (
    userType,
    companyId,
    filterProductId,
    poNumber,
    po,
    shipVia,
    shipTo,
    supplier,
    order_deadline,
    poDueDate,
    page,
    limit,
    ready_start_date,
    ready_end_date
  ) => {
    try {
      const response = await apiClient.get(
        endpoints.FILTER_PURCHASE_ORDER(
          userType,
          companyId,
          filterProductId,
          poNumber,
          po,
          shipVia,
          shipTo,
          supplier,
          order_deadline,
          poDueDate,
          page,
          limit,
          ready_start_date,
          ready_end_date
        )
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getPurchaseOrderIds: async (companyId, type, userId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PURCHASE_ORDER_IDS(companyId, type, userId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSinglePurchaseOrder: async (PoNumber) => {
    try {
      const response = await apiClient.get(endpoints.GET_SINGLE_PURCHASE_ORDER(PoNumber))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSupplierPurchaseOrder: async (supplierId) => {
    try {
      const response = await apiClient.get(endpoints.GET_SUPPLIER_PURCHASE_ORDER(supplierId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getProductsOnIds: async (ids) => {
    try {
      const response = await apiClient.get(endpoints.GET_PRODUCTS_ON_IDS(ids))
      return response
    } catch (error) {
      throw error.response
    }
  },

  UpdatePoSkuProposedQty: async (poId, productId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PO_SKU_PROPOSED_QTY(poId, productId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  UpdatePoSkuValidateQty: async (poId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PO_SKU_VALIDATE_QTY(poId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  sendTextMessage: async (input) => {
    try {
      const response = await apiClient.post(endpoints.SEND_TEXT_MESSAGE, input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllChats: async (role, companyId, userId) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_CHATS(role, companyId, userId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllPoChats: async (poId, page, limit) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_PO_CHATS(poId, page, limit))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllQueryChats: async (poId, page, limit) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_QUERY_CHATS(poId, page, limit))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllUnreadChats: async (userId) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_UNREAD_CHATS(userId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getPurchaseOrdersOnIds: async (ids) => {
    try {
      const response = await apiClient.get(endpoints.GET_PO_ON_IDS(ids))
      return response
    } catch (error) {
      throw error.response
    }
  },
  getDeliveryOnIds: async (ids) => {
    try {
      const response = await apiClient.post(endpoints.GET_DELIVERY_ON_IDS, ids)
      return response
    } catch (error) {
      throw error.response
    }
  },

  deletePurchaseOrder: async (poNumber) => {
    try {
      const response = await apiClient.delete(endpoints.DELETE_PURCHASE_ORDER(poNumber))
      return response
    } catch (error) {
      throw error.response
    }
  },

  updatePurchaseOrder: async (input) => {
    try {
      const response = await apiClient.put(endpoints.UPDATE_PURCHASE_ORDER(input.poNumber), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateisDispatch: async (id, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_IS_DISPATCH(id), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  createInitialDelivery: async (input) => {
    try {
      const response = await apiClient.post(endpoints.CREATE_INITIAL_DELIVERY, input)
      return response
    } catch (error) {
      throw error
    }
  },

  getDeliveriesOnPoNumber: async (poNumber) => {
    try {
      const response = await apiClient.get(endpoints.GET_DELIVERIES_ON_PO_NUMBER(poNumber))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSingleDeliveryOnDeliveryId: async (deliveryId) => {
    try {
      const response = await apiClient.get(endpoints.GET_SINGLE_DELIVERY_ON_DELIVERY_ID(deliveryId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateDelivery: async (deliveryId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_DELIVERY(deliveryId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateDeliveryStatus: async (deliveryId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_DELIVERY_STATUS(deliveryId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  updatePurchaseOrderStatus: async (poNumber, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PURCHASE_ORDER_STATUS(poNumber), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getPurchaseDeliveryOnPoNumber: async (ids) => {
    try {
      const response = await apiClient.get(endpoints.GET_PURCHASE_DELIVERY_ON_PO_NUMBER(ids))
      return response
    } catch (error) {
      throw error.response
    }
  },

  addProject: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_PROJECT, input)
      return response
    } catch (error) {
      throw error
    }
  },

  addProjectOdoo: async (input, type = process.env.REACT_APP_ODOO_API_KEY) => {
    try {
      const response = await odooClient.post(endpoints.CREATE_ODOO_PROJECT, input, { type })
      return response
    } catch (error) {
      throw error
    }
  },

  filterProject: async (
    companyId,
    projectId,
    bookingNumber,
    container,
    documents,
    freightLine,
    projectStage,
    loadDate,
    arrivalDate,
    deliveryDate,
    deliveryDateTo,
    selectMonth,
    warehouse,
    page,
    limit
  ) => {
    try {
      const response = await apiClient.get(
        endpoints.FILTER_PROJECT(
          companyId,
          projectId,
          bookingNumber,
          container,
          documents,
          freightLine,
          projectStage,
          loadDate,
          arrivalDate,
          deliveryDate,
          deliveryDateTo,
          selectMonth,
          warehouse,
          page,
          limit
        )
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllProjectonCompanyId: async (companyId) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_PROJECTS_ON_COMPANY_ID(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getProjectOnProjectId: async (projectId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PROJECT_BY_PROJECT_ID(projectId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getProjectHistory: async (projectId) => {
    try {
      const response = await apiClient.get(endpoints.GET_PROJECT_HISTORY(projectId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  deleteProjectOnProjectId: async (projectId) => {
    try {
      const response = await apiClient.delete(endpoints.DELETE_PROJECT_BY_PROJECT_ID(projectId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateProjectOnProjectId: async (input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PROJECT_BY_PROJECT_ID(input.projectId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  createDelivery: async (input) => {
    try {
      const response = await apiClient.post(endpoints.CREATE_PROJECT_DELIVERY, input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  createBackOrder: async (input) => {
    try {
      const response = await apiClient.post(endpoints.CREATE_BACKORDER, input)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getProjectDetailSearchSku: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_PROJECT_DETAIL_BY_SKU(query))
      return response
    } catch (error) {
      throw error.response
    }
  },
  getDeliveryByProjectId: async (projectId) => {
    try {
      const response = await apiClient.get(endpoints.GET_DELIVERY_BY_PROJECT_ID(projectId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSingleDeliveryId: async (deliveryId) => {
    try {
      const response = await apiClient.get(endpoints.GET_SINGLE_DELIVERY_ID(deliveryId))
      return response
    } catch (error) {
      throw error.response
    }
  },

  updateProjectDeliveryStatus: async (deliveryId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PROJECT_DELIVERY_STATUS(deliveryId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  UpdatePoInDelivery: async (deliveryId, input) => {
    try {
      const response = await apiClient.patch(endpoints.UPDATE_PO_IN_DELIVERY(deliveryId), input)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSalesData: async (
    startDate,
    endDate,
    page,
    limit,
    comparison,
    commerce_type,
    sortType,
    sortOrder,
    shop,
    country,
    warehouse,
    shop_type,
    sku,
    sku_filter
  ) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_SALES_DATA(startDate, endDate, page, limit)\
        endpoints.GET_SALES_DATA(
          startDate,
          endDate,
          page,
          limit,
          comparison,
          commerce_type,
          sortType,
          sortOrder,
          shop,
          country,
          warehouse,
          shop_type,
          sku,
          sku_filter
        )
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getShopShipmentData: async (
    startDate,
    endDate,
    comparison,
    commerce_type,
    shop,
    country,
    warehouse,
    shop_type,
    sku
  ) => {
    try {
      const response = await apiClient.get(
        endpoints.GET_PIE_CHART_DATA(
          startDate,
          endDate,
          comparison,
          commerce_type,
          shop,
          country,
          warehouse,
          shop_type,
          sku
        )
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getTotalSalesData: async (
    startDate,
    endDate,
    presetValue,
    groupDataValue,
    fromDate,
    toDate,
    comparison,
    commerce_type,
    shop,
    country,
    warehouse,
    shop_type,
    sku
  ) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_TOTAL_SALES_DATA(
        //   startDate,
        //   endDate,
        //   presetValue,
        //   groupDataValue,
        //   fromDate,
        //   toDate
        // )
        endpoints.GET_TOTAL_SALES_DATA(
          startDate,
          endDate,
          presetValue,
          groupDataValue,
          fromDate,
          toDate,
          comparison,
          commerce_type,
          shop,
          country,
          warehouse,
          shop_type,
          sku
        )
      )
      return response
    } catch (error) {
      throw error.response
    }
  },

  getGraphData: async (startDate, endDate, filterType, year, country = null, shop = null, commerce_type = null) => {
    try {
      const queryParams = new URLSearchParams({
        startDate,
        endDate,
        filterType,
        year,
        commerce_type
      })

      if (filterType === 'shop' && country) {
        queryParams.append('country', country)
      }

      if (filterType === 'country' && shop) {
        queryParams.append('shop', shop)
      }
      const response = await apiClient.get(endpoints.GET_GRAPH_DATA(queryParams))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSalesDataYears: async (dateObj) => {
    try {
      const response = await apiClient.get(endpoints.GET_SALES_DATA_YEARS(dateObj))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSaleslisting: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_SALES_LISTING(query))
      return response
    } catch (error) {
      throw error.response
    }
  },
  getSalesReportExcelDetails: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_SALES_REPORT_LISTING(query))
      return response
    } catch (error) {
      throw error.response
    }
  },

  DownloadPoDetail: async (query, type = process.env.REACT_APP_ODOO_API_KEY) => {
    try {
      const response = await odooClient.get(`${endpoints.DOWNLOAD_CONTENT(query)}`, { type })
      return response?.data
    } catch (error) {
      throw error.response
    }
  },

  // TRENDS MODULE API CALLING

  getSalesTrendlisting: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_SALES_TREND_LISTING(query))
      return response
    } catch (error) {
      throw error.response
    }
  },
  getStockAlertlisting: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_STOCK_ALERT_LISTING(query))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSalesReportByCountry: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_SALES_REPORT_BY_SHOP(query))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getWarehouseData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_WAREHOUSE_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSalesReportData: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_SALESREPORT_DATA(query))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getShopOverallData: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_SHOPOVERALL_DATA(query))
      return response
    } catch (error) {
      throw error.response
    }
  },

  getUniqueVendors: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_UNIQUEVENDORS_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getShipmentData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_SHIPMENT_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getShopData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_SHOP_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getSupplierData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_SUPPLIER_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  getAllSalesData: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_SALES_DATA)
      return response
    } catch (error) {
      throw error.response
    }
  },

  addAdvertisement: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_ADVERTISEMENT_DATA, input)
      return response
    } catch (error) {
      throw error
    }
  },

  getAdvertisementReport: async (query) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_SALES_DATA_YEARS(startDate, endDate)
        endpoints.GET_ADVERTISEMENT_REPORT(query)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },
  getTotalRevenue: async (query) => {
    try {
      const response = await apiClient.get(
        // endpoints.GET_SALES_DATA_YEARS(startDate, endDate)
        endpoints.GET_TOTAL_REVENUE(query)
      )
      return response
    } catch (error) {
      throw error.response
    }
  },
  getFilterListingAdvertisment: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_FILTER_LIST_ADVERTISEMENT(query))
      return response
    } catch (error) {
      throw error.response
    }
  },
  getAllAdvertismentData: async (page, filters) => {
    try {
      let query = endpoints.GET_ALL_ADVERTISEMENT_RAW(page)
      if (filters) {
        const filterParams = new URLSearchParams(filters)
        query += `&${filterParams.toString()}`
      }

      const response = await apiClient.get(query)
      return response
    } catch (error) {
      throw error.response
    }
  },

  addRefund: async (input) => {
    try {
      const response = await apiClient.post(endpoints.ADD_REFUND_DATA, input)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getRefundList: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_REFUND_DATA(query))
      return response
    } catch (error) {
      throw error
    }
  },
  getMarketplaceList: async () => {
    try {
      const response = await apiClient.get(endpoints.GET_MARKETPLACE_DATA)
      return response
    } catch (error) {
      throw error
    }
  },
  getOrderCountry: async (orderNumber) => {
    try {
      const response = await apiClient.get(endpoints.GET_COUNTRY_DATA(orderNumber))
      return response
    } catch (error) {
      throw error
    }
  },
  getLastImportedDate: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_LAST_IMPORTED_DATE(query))
      return response
    } catch (error) {
      throw error.response
    }
  },
  getSalesOrderData: async (orderNumber) => {
    try {
      const response = await apiClient.get(endpoints.GET_SELES_ORDER_DATA(orderNumber))
      return response
    } catch (error) {
      throw error
    }
  },
  getHnmReturnList: async (query) => {
    try {
      const response = await apiClientHnm.get(endpoints.GET_HNM_RETURN_DATA(query))
      return response
    } catch (error) {
      throw error
    }
  },
  updateOrderReturnStatus: async (input) => {
    try {
      const response = await apiClientHnm.put(endpoints.UPDATE_HNM_RETURN_STATUS(input?.orderNumber), input)

      return response
    } catch (error) {
      throw error
    }
  },

  // NOTIFICATIONS API
  getNotificationList: async (query) => {
    try {
      const response = await apiClient.get(endpoints.GET_ALL_NOTIFICATIONS(query))
      return response
    } catch (error) {
      throw error.response
    }
  },
  createNotification: async (input) => {
    try {
      const response = await apiClient.post(endpoints.CREATE_NOTIFICATION, input)
      return response
    } catch (error) {
      throw error.response
    }
  },
  markAsRead: (id) => {
    try {
      const response = apiClient.put(endpoints.MARK_AS_READ(id))
      return response
    } catch (error) {
      throw error.response
    }
  },
  markAllAsRead: (companyId) => {
    try {
      const response = apiClient.put(endpoints.MARK_ALL_AS_READ(companyId))
      return response
    } catch (error) {
      throw error.response
    }
  }
}

export default purchaseOrderService
